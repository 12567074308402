@import '/src/scss/theme-constants';

.orms-button {
  padding: 6px 8px;
  font-weight: $font-weight-normal;
  text-transform: uppercase;
  background-color: transparent;
  border: 0;
  border-radius: 4px;
}

.orms-button-primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 88px;
  height: 32px;
  font-weight: $font-weight-bold;
  color: $white;
  background-color: $brand-grey;
  border: 0;
  transition: background-color 0.3s $on-screen-transition;
  &:hover {
    background-color: $brand-orange;
  }
  &:disabled,
  &[disabled] {
    color: $disabled-grey;
    background-color: $disabled-light-grey;
  }
}

.orms-button-secondary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 82px;
  height: 32px;
  font-weight: $font-weight-bold;
  color: $brand-grey;
  background-color: transparent;
  border: 1px solid $secondary-grey;
  transition: color 0.3s $on-screen-transition;
  &:hover {
    color: $brand-orange;
    border-color: currentColor;
  }
  &:disabled,
  &[disabled] {
    color: $disabled-grey;
    border-color: $background-grey;
  }
}

.orms-button-grey {
  width: 102px;
  height: 32px;
  padding: 8px;
  font-weight: $font-weight-bold;
  line-height: 16px;
  color: $medium-grey;
  letter-spacing: 1px;
  background-color: #f3f2f2;
  transition: color 0.3s $on-screen-transition;
  &:hover {
    color: $brand-orange;
    border-color: currentColor;
  }
  &:disabled,
  &[disabled] {
    color: $disabled-grey;
    border-color: $background-grey;
  }
}

.orms-close-button {
  width: 24px;
  height: 24px;
  background-color: transparent;
  background-image: url('/assets/icons/icon-close.svg');
  border: 0;
  &:focus-visible {
    border: 0;
  }
}

.orms-link-button {
  padding: 0;
  color: $brand-grey;
  text-align: left;
  background: none;
  border: 0;

  &:hover {
    color: $brand-orange;
    text-decoration: none;
    cursor: pointer;
  }

  &:disabled,
  &[disabled] {
    color: $black;
    text-decoration: none;
    cursor: auto;
  }
}

.orms-button-a-primary {
  font-weight: $font-weight-bold;
  color: $white;
  background-color: $brand-grey;
  border: 0;
  transition: background-color 0.3s $on-screen-transition;
  &:hover {
    color: $white;
    text-decoration: none;
    cursor: pointer;
    background-color: $brand-orange;
  }
}

.orms-button-a-primary-disabled {
  color: $disabled-grey;
  pointer-events: none;
  background-color: $disabled-light-grey;
  &:hover {
    color: $disabled-grey;
    background-color: $disabled-light-grey;
    border-color: $background-grey;
  }
}
