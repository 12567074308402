$brand-main: #425563;
$brand-orange: #e7552f;
$brand-grey: #425563;
$grey: #757575;
$grey6: #212121;
$light-grey: #d8d8d8;
$v-light-grey: #e5e5e5;
$background-light-grey: #e0e0e0;
$background-grey: #eee;
$medium-grey: #888b8d;
$dark-grey: #53565a;
$secondary-grey: #9e9e9e;
$secondary-light-grey: #566e81;
$secondary-dark: #384751;
$status-grey: #595959;
$status-dark-grey: #1f1f1f;
$border: 1px solid $light-grey;
$box-sizing: border-box;
$border-radius: 4px;
$disabled-grey: #bdbdbd;
$disabled-unchecked-grey: #dedede;
$disabled-checked-grey: #a1aab1;
$disabled-light-grey: #f5f5f5;
$white: #fff;
$black: #000;
$sandybrown: #f4a460;
$alert-light: #0074e0;

$error-light: #dd3939;
$error-dark: #b52424;

$warning-light: #f3c200;
$warning-main: #e4ab18;

$success-light: #26c281;
$success-main: #229e17;

$dark-blue: #006dcc;
$dark-navy: #1a2935;

$dark-red: #e00000;
$light-red: #e54545;

$dark-green: #229e17;
$light-green: #26c281;

$dark-yellow: #e4ab18;
$light-yellow: #f3c200;

$dark-orange: #f90;
$light-orange: #fdb42d;

$on-screen-transition: cubic-bezier(0.55, 0, 0.1, 1);
// health
$green-health: #229e17;
$yellow-health: #fe0;
$orange-health: #e4ab18;
$red-health: #dd2c2c;
$none-health: $brand-grey;

$font-family: 'Source Sans Pro', Sans-Serif;

$font-weight-normal: 400;
$font-weight-thin: 300;
$font-weight-bold: 600;

$submit-request-snack-bar-z-index: 100;
