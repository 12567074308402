@import 'theme-constants';

.orms-shadow-input {
  padding: 0.375rem 0.5rem;
  margin-left: -0.5rem;
  line-height: 1;
  color: $black;
  text-overflow: ellipsis;
  border-color: transparent;
  transition: border-color 0.3s $on-screen-transition;

  &::placeholder {
    color: $secondary-grey;
  }
  &:hover {
    border-color: $light-grey;
  }
  &:focus {
    border-color: $brand-orange;
    box-shadow: none;
  }
  &.is-invalid:focus {
    box-shadow: none;
  }
  &.is-invalid {
    border-color: $error-light !important;
  }
  &:disabled,
  &[readonly] {
    background-color: transparent;
    &:hover,
    &:focus,
    &:active {
      border-color: transparent;
    }
  }
  &-prepend > div {
    padding-left: 0;
    background-color: transparent;
    border: 0;
  }
}
