@import '/src/scss/theme-constants';

.orms-expansion-panel {
  mat-panel-title {
    font-size: 16px;
    font-weight: $font-weight-bold;
    color: $brand-main;
    text-transform: uppercase;
  }

  .mat-expansion-panel:last-of-type {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .show-more {
    .mat-expansion-panel-header {
      border: 0 !important;
      .mat-expansion-indicator {
        padding-bottom: 4px;
      }
    }
  }

  .mat-expansion-panel-header {
    height: auto !important;
    padding: 15px 5px 15px 0 !important;
    background-color: transparent !important;
    border-bottom: 1px solid $light-grey !important;
    border-radius: 0% !important;
  }

  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header {
    background-color: transparent;
  }

  .mat-expansion-panel-body {
    padding: 0 !important;
  }
}
