@mixin orms-tooltip-base {
  position: relative;
  padding: 12px;
  overflow: visible;
  white-space: pre-line;
  background-color: $black;
  &::before {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 12px;
    height: 8px;
    content: '';
    background-image: url('/assets/icons/icon-tooltip-arrow.svg');
    background-repeat: no-repeat;
    background-size: 12px 8px;
    transform: translateX(-50%);
  }
}
.mat-tooltip.orms-tooltip {
  @include orms-tooltip-base;
}

.mat-tooltip.orms-tooltip-left {
  &::before {
    display: none;
  }
}

.mat-tooltip.orms-tooltip-common {
  @include orms-tooltip-base;
  margin: 6px;
  font-size: 12px;
  white-space: pre-line;
}
