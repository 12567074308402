@import '/src/scss/theme-constants';

.orms-editor {
  width: 100%;
  margin-bottom: 12px;
  .tox-tinymce {
    border-color: $secondary-grey;
    border-width: 1px !important;
    border-radius: 4px;
  }
  &.is-invalid .tox-tinymce {
    border-color: $error-light !important;
  }
}
