@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600&display=swap');

// Setting the font family via the typography (below) didn't work for everything, so ready-prebuilt-themes-indigo-pink is a copy of the
// legacy-prebuilt-themes-indigo-pink but with Roboto replaced with Source Sans Pro
@import 'ready-legacy-indigo-pink';

@import 'theme-constants';

// syncfusion - for tree only!
@import '@syncfusion/ej2-base/styles/material.css';
@import '@syncfusion/ej2-buttons/styles/material.css';
@import '@syncfusion/ej2-calendars/styles/material.css';
@import '@syncfusion/ej2-dropdowns/styles/material.css';
@import '@syncfusion/ej2-inputs/styles/material.css';
@import '@syncfusion/ej2-navigations/styles/material.css';
@import '@syncfusion/ej2-popups/styles/material.css';
@import '@syncfusion/ej2-splitbuttons/styles/material.css';
@import '@syncfusion/ej2-grids/styles/material.css';
@import '@syncfusion/ej2-treegrid/styles/material.css';
@import '@syncfusion/ej2-icons/styles/material';

@import '@angular/material/theming';
@import 'ngx-toastr/toastr';
@import '@ng-select/ng-select/themes/default.theme.css';

// bootstrap 4
$enable-validation-icons: false;
@import 'bootstrap/scss/bootstrap';

@import 'typography';
@import 'shadow-input';
@import 'toggle';
@import 'tooltip';
@import 'simple-input';
@import 'tabs';
@import 'dialog';
@import 'buttons';
@import 'table';
@import 'datepicker';
@import 'outline-input';
@import 'editor';
@import 'select';
@import 'scrollbar';
@import 'toast';
@import 'spinner';
@import 'expansion-panel';
@import 'resizable-table';
@import 'popups';
@import 'menu';
@import 'multiselect';

@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

@include mat-core();

$hatch-typography: mat-typography-config(
  $font-family: $font-family,
);

@include mat-typography-hierarchy($hatch-typography);

// Override typography on al Angular Material components so Source Sans Pro becomes the default font
@include angular-material-typography($hatch-typography);

body {
  display: flex;
  flex-wrap: nowrap;
  min-height: 100vh;
  margin: 0;
}

.app-root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.content {
  display: flex;
  flex: 1;
  align-items: stretch;
  align-self: stretch;
  width: 100%;
  height: calc(100vh - 60px);
}

.orms-label-margin {
  margin-bottom: 15px;
}

@media print {
  * {
    print-color-adjust: exact;
  }
}

@media all and (max-width: 800px) {
  .navigation {
    justify-content: space-around;
  }
}

@media all and (max-width: 500px) {
  .navigation {
    flex-direction: column;
  }
}

hr {
  width: 100%;
  height: 1px;
  background-color: $background-light-grey;
  border: 0;
}

.orms-section-title {
  padding: 15px 0;
  border-bottom: 1px solid $light-grey;
}

.orms-dl-30 {
  width: 30px;
}

.orms-dl-40 {
  width: 40px;
}

.orms-dl-50 {
  width: 50px;
}

.orms-dl-70 {
  width: 70px;
}

.orms-dl-80 {
  width: 80px;
}

.orms-dl-100 {
  width: 100px;
}

.orms-dl-120 {
  width: 120px;
}

.orms-dl-150 {
  width: 150px;
}
