@import '/src/scss/theme-constants';

.orms-active-link-tab {
  border-color: $brand-orange !important;
}

.orms-tabs .mat-ink-bar {
  background-color: $brand-orange !important;
}

.orms-link-tab,
.orms-tabs .mat-tab-label {
  min-width: auto !important;
  height: 32px !important;
  padding: 0 !important;
  margin: 0 12px;
  font-weight: $font-weight-bold !important;
  color: $brand-main;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
  opacity: 1 !important;
  &:hover {
    color: $brand-main;
    text-decoration: none;
  }
  &:first-child {
    margin-left: 24px;
  }
}
