@import 'theme-constants';

.orms-simple-input {
  padding: 4px 6px;
  border-color: $secondary-grey;
  transition: border-color 0.3s $on-screen-transition;
  &::placeholder {
    color: $secondary-grey;
  }

  &:hover {
    border-color: $light-grey;

    ~ .orms-simple-input-append > div {
      border-color: $light-grey;
    }
  }

  &:focus {
    border-color: $brand-orange;
    box-shadow: none;

    ~ .orms-simple-input-append > div {
      border-color: $brand-orange;
    }
  }

  &.is-invalid {
    border-color: $error-light !important;
  }
}

.orms-simple-input ~ .orms-simple-input-append > div {
  background-color: transparent;
  border-color: $secondary-grey;
  transition: border-color 0.3s $on-screen-transition;
}

.input-group-text.search-icon {
  padding: 0 12px;
}
