.resizable-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  &.resizing {
    cursor: col-resize;
    user-select: none;
  }
  .mat-cell {
    min-width: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    > button,
    > span {
      display: contents;
      text-overflow: ellipsis;
    }
  }
  .mat-header-cell {
    position: relative;
    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &:not(:last-child) {
      .resize-holder {
        position: absolute;
        top: 0;
        right: -10px;
        z-index: 1;
        width: 20px;
        height: 100%;
        overflow: visible;
        cursor: col-resize;
        &::after {
          position: absolute;
          top: 25px;
          left: 8px;
          width: 2px;
          height: calc(100% - 25px);
          content: '';
          background-color: transparent;
        }
        &:hover::after {
          background-color: $brand-orange;
        }
        &:active::after {
          height: 100vh;
        }
      }
    }
  }
  .mat-header-cell:hover {
    &:not(:nth-child(1)) {
      padding-right: 10px;
    }
  }
  @for $i from 1 through 20 {
    th:nth-child(#{$i}) {
      z-index: #{200 - 5 * $i} !important;
    }
  }
}
