@import '/src/scss/theme-constants';

.orms-table {
  width: 100%;
  th,
  td {
    padding: 0 5px !important;
    border: 0 !important;
  }
  th:first-child,
  td:first-child {
    padding-left: 24px !important;
  }
  th:last-child,
  td:last-child {
    padding-right: 24px !important;
  }
}
