@import '/src/scss/theme-constants';
.orms-outline-input {
  &.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: $brand-orange;
  }

  &.mat-form-field-appearance-outline.mat-hover .mat-form-field-outline-thick {
    color: $brand-orange !important;
  }

  &.mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
    color: $brand-orange;
  }
  &.mat-form-field.mat-form-field-invalid {
    .mat-input-element {
      color: $error-light;
    }
    .mat-form-field-label {
      color: $error-light;
      span {
        color: $error-light;
      }
    }
    .mat-form-field-ripple {
      background-color: $error-light;
    }
    .mat-form-field-invalid .mat-form-field-outline-thick {
      color: $error-light;
    }
  }

  &.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: $error-light;
  }

  &.mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-outline-thick {
    > * {
      border-width: 1px !important;
    }
  }

  &.mat-form-field.mat-focused .mat-form-field-label {
    color: $secondary-grey;
  }

  &.mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: $error-light !important;
  }

  &.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
    color: $error-light;
  }
}
