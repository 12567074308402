@import '/src/scss/theme-constants';

::ng-deep .orms-dialog-footer-actions {
  display: inline-flex;
  .mat-spinner {
    margin: 0 0 0 16px !important;
  }

  .spinner-wrapper {
    justify-content: unset !important;
    width: unset !important;
  }
}
