@import 'theme-constants';

body {
  font-family: $font-family !important;
  font-size: 14px;
  font-weight: $font-weight-normal;
  color: $black;
}

h1,
h2,
h3,
h4 {
  font-weight: $font-weight-thin;
}

h5,
h6 {
  font-weight: $font-weight-normal;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 34px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 12px;
}

small,
.small {
  font-size: 12px;
}

strong {
  font-weight: $font-weight-bold;
}

.orms-label {
  font-weight: $font-weight-bold;
  color: $brand-main;
  text-transform: uppercase;
}
