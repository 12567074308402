.orms-cr-submission-form {
  display: flex;
  flex-direction: column;
}

.orms-dialog-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
