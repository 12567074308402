@import '/src/scss/theme-constants';

.orms-datepicker-shadow-input {
  .mat-form-field-wrapper {
    padding: 0 !important;
    margin-top: 6px;

    .mat-datepicker-input {
      position: relative;
      width: 120px;
      padding: 9px 6px !important;
      font-size: 16px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: calc(100% - 6px) center;
      background-size: 16px;
      border: 1px solid transparent;
      border-radius: 4px;
      transition: border-color 0.3s $on-screen-transition;
      &:hover {
        background-image: url('/assets/icons/icon-calendar-light.svg');
        border-color: $light-grey;
      }
      &:focus {
        background-image: url('/assets/icons/icon-calendar.svg');
        border-color: $brand-orange;
      }
      &:disabled,
      &[readonly] {
        color: $black;
        cursor: text;
        background-image: none;
        border-color: transparent;
      }
      &.is-invalid:focus {
        border-color: $error-light !important;
      }
    }
  }
  .mat-form-field-flex {
    display: flex;
  }
  .mat-form-field-infix {
    width: auto;
    padding: 0 !important;
    margin: -6px 0 0 -6px;
    border: 0;
  }
  .mat-form-field-underline {
    display: none;
  }
  .mat-button-wrapper {
    svg {
      display: none;
    }
  }
}

.orms-datepicker {
  height: 387px !important;
  padding-bottom: 58px;
  background-color: #fff;
  .mat-calendar-body-cell-content {
    border: 0;
  }
  .mat-calendar-body-selected {
    color: $brand-orange;
    background-color: transparent;
    border: 1px solid currentColor;
  }
  .mat-calendar-body-cell:hover .mat-focus-indicator {
    background-color: transparent !important;
    border: 1px solid $light-grey;
  }
}

.mat-datepicker-actions {
  // no specific handler available
  padding: 0 !important;
}
