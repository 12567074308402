@import '/src/scss/theme-constants';

.orms-toast {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 396px !important;
  min-height: 64px !important;
  padding: 9px 57px 9px 22px !important;
  margin-top: 15px;
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  background-size: 27px;
  border-style: solid;
  border-width: 2px;
  border-radius: 50px !important;
  box-shadow: 0 6px 4px rgba(0, 0, 0, 0.25);
  &.toast-error {
    background-color: $error-light;
    background-image: url('/assets/icons/icon-alert-error.svg');
    border-color: $error-dark;
  }

  &.toast-warning {
    background-color: $warning-light;
    background-image: url('/assets/icons/icon-alert-warning.svg');
    border-color: $warning-main;
  }

  &.toast-success {
    background-color: $success-light;
    background-image: url('/assets/icons/icon-action-check-circle.svg');
    border-color: $success-main;
  }
}
