@import 'theme-constants';

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: $background-light-grey;
}

::-webkit-scrollbar-thumb {
  background-color: $brand-main;
  border-radius: 8px;
}
