@import '/src/scss/theme-constants';

.orms-toggle-switch-container {
  min-height: 38px;
  pointer-events: none;
  transform: scale(1.5);
  transform-origin: left top;
}

.orms-toggle-switch:checked ~ .custom-control-label::before {
  background-color: $brand-main;
  border: 0;
}

.orms-toggle-switch:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba($color: $light-grey, $alpha: 0.4);
}

.orms-toggle-switch ~ .custom-control-label::before {
  background-color: $disabled-grey;
}

.orms-toggle-switch ~ .custom-control-label::after {
  background-color: $white;
}

.orms-toggle-switch ~ .custom-control-label::before {
  border: 0;
}

.orms-toggle-switch:disabled ~ .custom-control-label::before {
  background-color: $disabled-unchecked-grey !important;
}

.orms-toggle-switch:disabled:checked ~ .custom-control-label::before {
  background-color: $disabled-checked-grey !important;
}
