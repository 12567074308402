@import 'theme-constants';

.orms-multiselect {
  .ng-placeholder {
    position: static !important;
  }
  .ng-select-container {
    min-width: 100px;
    overflow: visible;
    border: 0 !important;
    box-shadow: none !important;
  }
  .ng-arrow-wrapper .ng-arrow {
    top: 0 !important;
    border-color: $grey transparent transparent !important;
    border-style: solid;
    border-width: 5px 5px 2.5px !important;
    border-top-color: $grey !important;
  }
  .ng-input {
    display: none;
  }
  .ng-placeholder {
    color: $brand-main !important;
    cursor: pointer;
  }
  .ng-value-label {
    cursor: pointer;
  }
  .ng-select-container .ng-value-container .ng-value {
    font-size: 16px !important;
    color: $black;
    background-color: transparent !important;
  }
  .ng-dropdown-panel {
    top: 35px;
    right: 0;
    left: auto;
    width: 256px;
    border-radius: 4px;
  }
  .ng-dropdown-header {
    padding: 13px;
    border-bottom: 0;
  }
  .ng-dropdown-panel-items {
    padding-left: 13px;
    margin-right: 13px;
    margin-bottom: 13px;
  }
  .ng-dropdown-footer {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    border-top: 0;
    button {
      margin: 0 13px 13px;
      img {
        position: relative;
        top: -1px;
        width: 19px;
      }
    }
  }
  .ng-dropdown-panel.ng-select-bottom {
    margin-top: 4px;
    filter: drop-shadow(0 0 16px rgba(0, 0, 0, 0.25));
    border: 0;
    box-shadow: none;
    .ng-dropdown-panel-items .ng-option.ng-option-selected,
    .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
      color: $brand-orange;
      background-color: $background-grey;
    }
    .ng-option.ng-option-marked {
      background-color: $disabled-light-grey;
    }
  }
}

.orms-multiselect-search {
  width: 100%;
  padding: 4px;
  color: $brand-main;
  border: 1px solid $secondary-grey;
  border-radius: 3px;
  &:focus,
  &:active {
    border-color: $brand-orange;
    outline: none;
  }
}

.orms-multiselect-checkbox {
  label,
  input {
    pointer-events: none;
  }
  .custom-control-label::before,
  .custom-control-label::after {
    top: 4px;
    width: 16px;
    height: 16px;
    background-size: 60%;
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }
  .custom-control-label::before {
    border: 2px solid $brand-main;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $brand-main;
    border-color: $brand-main;
  }
}
