.orms-select .mat-select-trigger {
  padding: 8px 0 9px;
}

.orms-select-options {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.25) !important;
  .mat-selected {
    color: $brand-orange;
    background-color: $background-grey !important;
  }
}

.mat-select-value {
  color: $brand-main !important;
}

.mat-select-disabled .mat-select-arrow {
  visibility: hidden !important;
}
